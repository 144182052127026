<template>
  <div>
    <b-navbar
      fixed="top"
      class="pt-0 pb-0"
      toggleable="lg"
      id="navbar-cabecera-manusa"
    >
      <b-navbar-brand href="/" style="min-width:190px">
        <b-img
          src="/img/manusa-logo-negative.png"
          style="height: 32px; margin:20px 15px"
          alt="Manusa"
        ></b-img>
      </b-navbar-brand>
      <b-navbar-nav class="d-none d-lg-block ml-auto mr-auto">
        <div style="font-size:2em">
          {{ this.titulopagina }}
          <span v-show="vuexSeccion.length" class="text-uppercase"
            >- {{ vuexSeccion }}</span
          >
        </div>
      </b-navbar-nav>
      <b-navbar-nav class="ml-auto m-0 p-0">
        <b-nav-item-dropdown right style="color:white" text-right no-caret>
          <template v-slot:button-content v-if="vuexAuthUsuario">
            <b-avatar
              size="3em"
              :text="vuexAcronimoUsuario"
              variant="primary"
              class="m-0 p-0"
            ></b-avatar>
          </template>
          <div v-if="vuexAuthUsuario">
            <div>
              <b-dropdown-text>{{ vuexNombreUsuario }}</b-dropdown-text>
              <b-dropdown-divider></b-dropdown-divider>
              <b-dropdown-text>{{
                $t("lang.barramanusa.cambiaridioma")
              }}</b-dropdown-text>
              <b-dropdown-item
                v-for="l in locales"
                :key="'locale-' + l.codigo"
                href="#"
                @click="cambiaridioma(l.codigo)"
                ><b-img
                  :src="'/img/banderas/' + l.codigo + '.png'"
                  height="18"
                  class="mr-1"
                ></b-img
                >{{ l.nombre }}</b-dropdown-item
              >
              <b-dropdown-divider></b-dropdown-divider>
              <b-dropdown-item href="#" @click="logout">{{
                $t("lang.barramanusa.desconectar")
              }}</b-dropdown-item>
            </div>
          </div>
        </b-nav-item-dropdown>
      </b-navbar-nav>
    </b-navbar>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "BarraManusa",
  props: {
    titulopagina: String
  },
  data() {
    return {
      locales: []
    };
  },
  mounted() {
    const LOCALES = [
      { codigo: "es", nombre: "Español" },
      { codigo: "en", nombre: "English" },
      { codigo: "fr", nombre: "Français" },
      { codigo: "pt", nombre: "Português" }
    ];
    LOCALES.forEach(l => {
      if (sessionStorage.getItem("locale") !== l.codigo) this.locales.push(l);
    });
  },
  methods: {
    cambiaridioma(locale) {
      sessionStorage.setItem("locale", locale);
      document.location.reload();
    },
    async logout() {
      await this.$store.dispatch("user/logout");
      console.log(this.$router);

      if (this.$router.history.current.name != "Home")
        return this.$router.replace("/");
    }
  },
  computed: {
    ...mapGetters({
      vuexSeccion: "seccion",
      vuexUsernameUsuario: "user/username",
      vuexAcronimoUsuario: "user/acronimo",
      vuexEmailUsuario: "user/email",
      vuexTokenUsuario: "user/token",
      vuexNombreUsuario: "user/nombre",
      vuexAuthUsuario: "user/autenticado"
    })
  }
};
</script>

<style scoped>
#navbar-cabecera-manusa {
  background-color: var(--color-manusa-azul) !important;
  color: #fff !important;
}
#navbar-cabecera-manusa h1,
#navbar-cabecera-manusa h2,
#navbar-cabecera-manusa h3,
#navbar-cabecera-manusa a:link,
#navbar-cabecera-manusa a:visited {
  color: #fff !important;
}
#navbar-cabecera-manusa a:link,
#navbar-cabecera-manusa a:hover {
  color: rgb(221, 221, 221) !important;
}
</style>
