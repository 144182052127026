import Vue from "vue";
import App from "./App.vue";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import router from "./router";
import store from "./store";
import i18n from "./lang";

Vue.config.productionTip = false;

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
require("@/assets/styles/manusa.css");
require("@/assets/styles/edocuments.css");

// se obtien el queryString de la url
const queryString = window.location.search;
console.log(window.location);
if (window.location.pathname !== "/error-conexion") {
  if (queryString) {
    //se obtiene el token del queryString
    let token = queryString.split("token=")[1].split("&")[0];
    console.log(token);
    if (token.length) {
      //se almacena el token el session del navegador
      sessionStorage.setItem("token", token);
    }
  }
  ////console.log(token, sessionStorage.getItem('token'))

  // si no hay sesion-locale almacenada, se crea (según navegador)
  if (!sessionStorage.getItem("locale")) {
    let l = navigator.language.split("-")[0];
    if (["es", "en", "pt", "fr"].indexOf(l) < 0) l = "en";
    sessionStorage.setItem("locale", l);
  }

  // si hay sesion-token almacenada...
  if (sessionStorage.getItem("token")) {
    console.log(sessionStorage.getItem("token"));
    // se actualiza el valor del Token en Vuex
    store.dispatch("user/setToken", sessionStorage.getItem("token"));
    // se piden los datos del usuario para cargar en Vuex
    store.dispatch("user/getUser");
  }
  // es posible que la primera llamada no tenga history, de modo que se indica "home" por defecto
  const nombrePaginaActual = router.history.current.name
    ? router.history.current.name.toLowerCase()
    : "home";
  console.log("nombrePaginaActual", nombrePaginaActual);
  console.log("sessionStorage-token", sessionStorage.getItem("token"));
  // si se intenta ir a otra página que no sea home sin estar autenticado, se redirige a home
  if (!store.state.user.autenticado && nombrePaginaActual != "home") {
    router.replace("/");
  }
  // si está en home pero no hay registro de sesion token, se redirige a error de conexión
  if (!sessionStorage.getItem("token") && nombrePaginaActual == "home") {
    document.location.href = "/error-conexion";
  }
}
i18n.locale = sessionStorage.getItem("locale");

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount("#app");
