import Vue from "vue";
import Vuex from "vuex";
import userStore from "./user";

Vue.use(Vuex);
export default new Vuex.Store({
  strict: process.env.NODE_ENV !== "production",
  state: {
    seccion: "INICIO"
  },
  mutations: {
    SET_SECCION(state, seccion) {
      state.seccion = seccion;
    }
  },
  actions: {},
  modules: {
    user: userStore
  },
  getters: {
    seccion: state => {
      return state.seccion;
    },
    emailusuario: state => {
      return state.user.email;
    },
    tokenusuario: state => {
      return state.user.token;
    }
  }
});
