import Vue from "vue";
import VueRouter from "vue-router";
import axios from "axios";
import VueAxios from "vue-axios";
import VueI18n from "vue-i18n";
import Home from "../views/Home.vue";

Vue.use(VueRouter);
Vue.use(VueAxios, axios);
Vue.use(require("vue-moment"));
Vue.use(VueI18n);

Vue.axios.defaults.baseURL =
  process.env.NODE_ENV !== "production"
    ? process.env.VUE_APP_API_MANUSA_DEV
    : process.env.VUE_APP_API_MANUSA_PROD;

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/error-conexion",
    name: "ErrorConexion",
    component: () => import("../views/ErrorConexion.vue")
  },
  {
    path: "*",
    name: "NotFound",
    component: () => import("../views/NotFound.vue")
  }
];

const router = new VueRouter({
  routes,
  linkActiveClass: "active",
  mode: "history",
  base: process.env.BASE_URL
});

export default router;
