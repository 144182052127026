<template>
  <div>
    <div class="py-2">
      <b-form-group>
        <b-input-group>
          <b-form-input
            v-model="filter"
            type="search"
            id="filterInput"
            debounce="500"
            :placeholder="$t('lang.edocuments.filtro.placeholder')"
            @change="filtros()"
          ></b-form-input>
          <b-input-group-append
            v-b-popover.hover="$t('lang.edocuments.filtro.dondebuscadetalle')"
            :title="$t('lang.edocuments.filtro.dondebusca')"
            ><b-icon-question-circle
              font-scale="2.1"
              class="ml-1"
            ></b-icon-question-circle
          ></b-input-group-append>
        </b-input-group>
        <b-row class="my-2">
          <b-col md="3">
            <b-form-select
              v-model="filtrotipologia"
              :options="tipologias.filter(t => t.text !== '')"
            >
              <template #first>
                <b-form-select-option value=""
                  >--
                  {{ $t("lang.edocuments.filtro.seleccionartipologia") }}
                  --</b-form-select-option
                >
              </template></b-form-select
            >
          </b-col>
          <b-col md="3">
            <b-form-select
              v-model="filtrotipodocumento"
              :options="tiposdedocumento.filter(t => t.text !== '')"
            >
              <template #first>
                <b-form-select-option value=""
                  >--
                  {{ $t("lang.edocuments.filtro.seleccionartipodocumento") }}
                  --</b-form-select-option
                >
              </template></b-form-select
            >
          </b-col>
          <b-col md="3">
            <b-form-select
              v-model="filtrosubfamilia"
              :options="subfamilias.filter(t => t.text !== '')"
            >
              <template #first>
                <b-form-select-option value=""
                  >--
                  {{ $t("lang.edocuments.filtro.seleccionarsubfamilia") }}
                  --</b-form-select-option
                >
              </template></b-form-select
            >
          </b-col>
          <b-col md="3">
            <b-form-select
              v-model="filtroformato"
              :options="formatos.filter(t => t.text !== '')"
            >
              <template #first>
                <b-form-select-option value=""
                  >--
                  {{ $t("lang.edocuments.filtro.seleccionarformato") }}
                  --</b-form-select-option
                >
              </template></b-form-select
            >
          </b-col>
        </b-row>
      </b-form-group>
    </div>
    <div class="">
      <b-row>
        <b-col>
          <b-pagination
            class="mt-5 mt-md-0"
            v-model="currentPage"
            :total-rows="totalItemsFiltrados"
            :per-page="perPage"
            aria-controls="my-table"
          ></b-pagination
        ></b-col>
      </b-row>
      <div class="font-italic">{{ resultadofiltro }}</div>
      <b-table
        striped
        hover
        fixed
        stacked="lg"
        id="my-table"
        ref="resultadodebusqueda"
        :current-page="currentPage"
        :per-page="perPage"
        :busy.sync="isBusy"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :items="itemsFiltrados"
        :fields="fields"
        :tbody-tr-class="rowClass"
        :no-provider-paging="true"
        :no-provider-sorting="true"
        :no-provider-filtering="true"
        sort-icon-left
      >
        <!--:filter="filter"
        :filter-included-fields="[
          'TypologyDocument',
          'TypeDocument',
          'ExtFile',
          'No',
          'Description',
          'Subfamily'
        ]"
        @filtered="onFiltered"
        -->
        <template v-slot:cell(descripcion)="row">
          {{ row.item.descripcion }}
          <div class="subfamilia">
            {{ row.item.No }}
            <span class="ml-2">{{ row.item.subfamily.join(" - ") }}</span>
          </div>
        </template>
        <template v-slot:cell(files)="row">
          <b-avatar
            v-for="f in row.item.files.split('|')"
            :key="f"
            button
            kk-v-b-modal.modal-embed
            @click.prevent="descargarfichero(row.item, f.split(';'))"
            :text="f.split(';')[0]"
            class="mx-1"
            variant="white"
            :src="
              '/img/iconosdescarga/' + f.split(';')[0].toLowerCase() + '.png'
            "
            :title="f.split(';').join(' - ')"
          ></b-avatar>
        </template>
      </b-table>
      <b-row>
        <b-col>
          <b-pagination
            class="mt-5 mt-md-0"
            v-model="currentPage"
            :total-rows="totalItemsFiltrados"
            :per-page="perPage"
            aria-controls="my-table"
          ></b-pagination
        ></b-col>
      </b-row>
    </div>
    <div v-if="isBusy">
      <b-spinner label="Spinning"></b-spinner>
    </div>
    <b-modal centered size="xl" id="modal-embed" :title="tituloContent">
      <div v-if="showmodalembed && typeContent === 'img'" class="text-center">
        <b-img
          :src="srcContent"
          fluid
          :alt="tituloContent"
          class="mx-auto"
        ></b-img>
      </div>
      <b-embed
        v-if="showmodalembed && typeContent != 'img'"
        :type="typeContent"
        :aspect="aspectContent"
        :src="srcContent"
        allowfullscreen
      ></b-embed>
      <div v-if="!showmodalembed">
        <div class="text-center">
          <b-spinner variant="primary" label="Text Centered"></b-spinner>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
//import AbrirPDF from "@/components/AbrirPDF.vue";
class Quitaracentos {
  constructor() {
    var mapa = [
      { letra: "a", acentos: "áàäâa" },
      { letra: "e", acentos: "éèëêe" },
      { letra: "i", acentos: "íìïîi" },
      { letra: "o", acentos: "óòöôo" },
      { letra: "u", acentos: "úùüûu" },
      { letra: "c", acentos: "çc" },
      { letra: "n", acentos: "ñn" }
    ];
    var mapa2 = [];
    mapa.forEach(m => {
      m.acentos = m.acentos.split("");
      m.acentos.forEach(mm => (mapa2[mm] = m.letra));
    });
    this.mapa = mapa2;
    //console.log(this.mapa);
  }
  convertirtexto(texto) {
    let txtcnv = "";
    if (texto && texto.length)
      texto
        .toLowerCase()
        .split("")
        .forEach(t => {
          txtcnv += this.mapa[t] || t;
        });
    return txtcnv;
  }
}

export default {
  components: {
    //  AbrirPDF
  },
  data() {
    return {
      datosdescargados: false,
      filtrotipologia: "",
      filtrosubfamilia: "",
      filtroformato: "",
      filtrotipodocumento: "",
      showmodalembed: false,
      typeContent: "iframe",
      srcContent: "",
      tituloContent: "",
      aspectContent: "16by9",
      currentPage: 1,
      perPage: 20,
      itemsoriginal: [],
      itemsFiltrados: [],
      cargandoitems: true,
      isBusy: false,
      sortBy: "descripcion",
      sortDesc: false,
      titulopdf: "",
      srcpdf: "",
      fields: [
        //{ key: "No", label: "Título", class: "align-middle", sortable: true },
        {
          key: "descripcion",
          label: this.$t("lang.edocuments.descripcion"),
          class: "descripcion align-middle",
          sortable: true
        },
        // { key: "subfamily", label: "Subfamilia" },
        {
          key: "Typology",
          label: this.$t("lang.edocuments.tipologia"),
          class: "align-middle",
          formatter: value => {
            return this.$t("lang.edocuments.tipologias." + value);
          },
          sortable: true
        },
        {
          key: "Type",
          label: this.$t("lang.edocuments.tipo"),
          class: "align-middle",
          formatter: value => {
            return this.$t("lang.edocuments.tiposdedocumento." + value);
          },
          sortable: true
        },
        {
          key: "ExtFile",
          label: this.$t("lang.edocuments.formato"),
          class: "align-middle",
          sortable: true
        },
        {
          key: "files",
          label: this.$t("lang.edocuments.descarga"),
          class: "descarga align-middle"
        }
      ],
      resultadofiltro: "",
      filter: null,
      filterOn: [],
      totalItemsFiltrados: 0,
      tipologias: [],
      formatos: [],
      subfamilias: [],
      tiposdedocumento: []
    };
  },
  mounted() {
    this.cargaritems();
  },
  methods: {
    descargarfichero(it, NameFile) {
      this.showmodalembed = false;
      if (NameFile.length > 1) {
        let filename = NameFile[1];
        let formato = it.ExtFile.toLowerCase();
        if (["vid", "png", "jpg", "pdf"].indexOf(formato) >= 0) {
          this.$bvModal.show("modal-embed");
        }
        if (["vid"].indexOf(formato) >= 0) {
          // p.e. https://www.youtube.com/watch?v=0UCPRJ2UPac&feature=youtu.be
          if (filename.split("https://www.youtube.com/watch?v=").length > 1) {
            let idy = filename.split("&")[0];
            idy = idy.split("https://www.youtube.com/watch?v=")[1];
            filename =
              "https://www.youtube.com/embed/" +
              idy +
              "?enablejsapi=1&origin=https://manusa.com";
          }
          this.srcContent = filename;
          this.tituloContent = it.descripcion;
          this.typeContent = "iframe";
          this.aspectContent = "16by9";
          this.showmodalembed = true;
          //console.log(filename);
        } else {
          //console.log("descargado", it, formato);
          const params = {
            filename: filename
          };

          if (formato === "pdf") {
            this.axios
              .post("/descargar-documento", params, {
                responseType: "json",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${sessionStorage.getItem("token")}`
                }
              })
              .then(res => {
                const nombre = filename; // it.Cod_cliente + "." + formato;

                //console.log("descargado", res);
                if (res.status === 401) document.location.href = "/";
                if (res.status > 400) return;
                //document.location.href = res.data;
                //this.showFile(res.data);

                //const blob = new Blob([res.data]);
                //console.log(blob);
                //console.log("res.data.resultado", res.data.resultado);
                //console.log("formato", formato);
                var newBlob = this.b64toBlob(
                  res.data.resultado.contenido,
                  "data:application/pdf"
                );
                const downloadUrl = window.URL.createObjectURL(newBlob);
                const link = document.createElement("a");
                link.href = downloadUrl;
                link.setAttribute("download", nombre); //any other extension
                document.body.appendChild(link);
                link.click();
                link.remove();
                this.$bvModal.hide("modal-embed");
              })
              .catch(function(error) {
                console.log(error);
              })
              .then(function() {
                // always executed
              });
          } else {
            //var _t = this;
            this.axios
              .post("/descargar-documento", params, {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${sessionStorage.getItem("token")}`
                }
              })
              .then(res => {
                const nombre = filename; // it.Cod_cliente + "." + formato;

                //console.log("descargado", res);
                if (res.status === 401) document.location.href = "/";
                if (res.status > 400) return;
                //document.location.href = res.data;
                //this.showFile(res.data);

                // console.log("res.data.resultado", res.data.resultado);
                // console.log("formato", formato);
                let ct = "application/object";
                let prefijobase64 = "";
                switch (formato) {
                  case "pdf":
                    ct = "application/pdf";
                    prefijobase64 = "data:application/pdf;base64,";
                    break;
                  case "jpg":
                    ct = "image/jpg";
                    prefijobase64 = "data:image/jpg;base64,";
                    break;
                  case "png":
                    ct = "image/png";
                    prefijobase64 = "data:image/png;base64,";
                    break;
                }
                if (["pdf"].indexOf(formato) >= 0) {
                  this.srcContent = "";
                  this.tituloContent = "";
                  this.showmodalembed = true;
                  this.tituloContent = it.descripcion;
                  this.typeContent = "iframe";
                  this.aspectContent = "16by9";
                  this.srcContent =
                    prefijobase64 + res.data.resultado.contenido;
                } else if (["png", "jpg"].indexOf(formato) >= 0) {
                  this.srcContent = "";
                  this.tituloContent = "";
                  this.showmodalembed = true;
                  this.tituloContent = it.descripcion;
                  this.typeContent = "img";
                  this.aspectContent = "img";
                  this.srcContent =
                    prefijobase64 + res.data.resultado.contenido;
                  //console.log(prefijobase64);
                } else this.showFile(res.data.resultado.contenido, ct, nombre);
              })
              .catch(function(error) {
                console.log(error);
              })
              .then(function() {
                // always executed
              });
          }
        }
      }
    },
    b64toBlob: (b64Data, contentType = "", sliceSize = 512) => {
      //console.log(b64Data);
      const byteCharacters = atob(b64Data);
      const byteArrays = [];

      for (
        let offset = 0;
        offset < byteCharacters.length;
        offset += sliceSize
      ) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }

      const blob = new Blob(byteArrays, { type: contentType });
      return blob;
    },
    showFile(b64Data, contenttype, nombre) {
      // It is necessary to create a new blob object with mime-type explicitly set
      // otherwise only Chrome works like it should
      var newBlob = this.b64toBlob(b64Data, contenttype);

      // IE doesn't allow using a blob object directly as link href
      // instead it is necessary to use msSaveOrOpenBlob
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(newBlob);
        return;
      }

      // For other browsers:
      // Create a link pointing to the ObjectURL containing the blob.
      const data = window.URL.createObjectURL(newBlob);
      var link = document.createElement("a");
      link.href = data;
      link.download = nombre;
      link.click();
      setTimeout(function() {
        // For Firefox it is necessary to delay revoking the ObjectURL
        window.URL.revokeObjectURL(data);
      }, 100);
    },

    rowClass(item, type) {
      if (!item || type !== "row") return;
      return;
    },
    cargaritems() {
      this.isBusy = true;
      var _t = this;
      this.axios
        .post(
          "/obtener-documentos-segun-perfil-usuario",
          {},
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${sessionStorage.getItem("token")}`
            }
          }
        )
        .then(res => {
          if ("error" in res.data && res.data.error === "SESIONCADUCADA")
            document.location.href = "/";

          res.data.resultado.forEach(r => {
            r["descripcion"] = null;
            let idi = sessionStorage.getItem("locale");
            let langdesc = r.descripciones.split("_").join(" ");
            langdesc = langdesc.split("|");
            if (langdesc.length > 1) {
              langdesc.forEach(ld => {
                ld = ld.split(";");
                ld[0] = ld[0].toLowerCase();
                if (ld[0] === idi) r.descripcion = ld[1];
              });
              if (!r.descripcion) r.descripcion = langdesc[0];
            } else {
              r.descripcion = langdesc[0].split(";")[1];
            }
            r.subfamily = r.subfamily.split(",");
            _t.itemsoriginal.push(r);
          });
          _t.$refs.resultadodebusqueda.refresh();
          _t.isBusy = false;
          _t.cargarFiltros(res.data.resultado);
          _t.onFiltered(res.data.resultado);
        })
        .catch(function(error) {
          console.log(error);
        })
        .then(function() {
          // always executed
        });
    },
    sortTextOptions(data) {
      const type = "asc";
      let ordered = [];
      let dataArr = [].slice.call(data);
      let dataSorted = dataArr.sort((a, b) => {
        if (type === "asc") {
          if (a.text < b.text) return -1;
          else return 1;
        } else {
          if (a.text > b.text) return -1;
          else return 1;
        }
      });
      dataSorted.forEach(e => ordered.push(e));
      return ordered;
    },
    cargarFiltros(items) {
      if (!this.tipologias.length) {
        let tipologias = [];
        let formatos = [];
        let subfamilias = [];
        let tiposdedocumento = [];
        let a = new Quitaracentos();
        //console.group("QuitarAcentos");
        items.forEach(it => {
          it["typologybusqueda"] = a.convertirtexto(it.Typology);
          it["typedocumentbusqueda"] = a.convertirtexto(it.TypeDocument);
          it["extfilebusqueda"] = a.convertirtexto(it.ExtFile);
          it["nobusqueda"] = a.convertirtexto(it.No);
          it["descripcionesbusqueda"] = a.convertirtexto(it.descripcion);
          it["subfamilybusqueda"] = a.convertirtexto(it.Subfamily);
          //console.log(it.descripcion, it["descripcionesbusqueda"]);

          // Tipologias
          //console.log(it.id, it.Typology, it.TypologyDocument);
          if (tipologias.indexOf(it.Typology) === -1)
            tipologias.push(it.Typology);

          // Formatos
          if (formatos.indexOf(it.ExtFile) === -1) formatos.push(it.ExtFile);

          // Tipos de documentación
          if (tiposdedocumento.indexOf(it.Type) === -1)
            tiposdedocumento.push(it.Type);

          // Subfamilias
          it.subfamily.forEach(its => {
            let lstsf = its.split(",");
            lstsf.forEach(sf => {
              if (subfamilias.indexOf(sf) === -1) subfamilias.push(sf);
            });
          });
          //
        });
        //console.groupEnd("QuitarAcentos");

        // console.log("tipologias.length", tipologias.length);
        this.tipologias = [];
        tipologias.forEach(it => {
          this.tipologias.push({
            value: it,
            text: this.$t("lang.edocuments.tipologias." + it)
          });
        });
        this.tipologias = this.sortTextOptions(this.tipologias);

        // console.log("formatos.length", formatos.length);
        this.formatos = [];
        formatos.forEach(it => {
          this.formatos.push({
            value: it,
            text: it
          });
        });
        this.formatos = this.sortTextOptions(this.formatos);

        // console.log("tiposdedocumento.length", tiposdedocumento.length);
        this.tiposdedocumento = [];
        tiposdedocumento.forEach(it => {
          this.tiposdedocumento.push({
            value: it,
            text: this.$t("lang.edocuments.tiposdedocumento." + it)
          });
        });
        this.tiposdedocumento = this.sortTextOptions(this.tiposdedocumento);

        // console.log("subfamilias.length", subfamilias.length);
        this.subfamilias = [];
        subfamilias.forEach(it => {
          this.subfamilias.push({
            value: it,
            text: it
          });
        });
        this.subfamilias = this.sortTextOptions(this.subfamilias);
      }
    },
    onFiltered(itemsFiltrados) {
      if (itemsFiltrados) this.itemsFiltrados = itemsFiltrados;
      this.currentPage = 1;
      this.totalItemsFiltrados = this.itemsFiltrados.length;
      // console.log("totalItemsFiltrados", this.totalItemsFiltrados);
      this.resultadofiltro =
        this.$t("lang.edocuments.documentosencontrados", {
          num: this.itemsoriginal.length
        }) +
        (this.itemsFiltrados.length === this.itemsoriginal.length
          ? ""
          : " (" +
            this.$t("lang.edocuments.filtrados", {
              num: this.itemsFiltrados.length
            }) +
            ")");
    },

    filtros() {
      //console.log("filtros");
      this.isBusy = true;
      this.itemsFiltrados = [];
      let lst = [];
      let a = new Quitaracentos();
      if (!this.filter || this.filter == "") {
        lst = this.itemsoriginal.filter(() => {
          return true;
        });
        //console.log("lst", lst);
      } else {
        let txtfiltro = a.convertirtexto(this.filter);
        //console.log(txtfiltro);
        lst = this.itemsoriginal.filter(it => {
          //console.log( txtfiltro, it["descripcionesbusqueda"],it["descripcionesbusqueda"].indexOf(txtfiltro));
          return (
            it["typologybusqueda"].indexOf(txtfiltro) >= 0 ||
            it["typedocumentbusqueda"].indexOf(txtfiltro) >= 0 ||
            it["extfilebusqueda"].indexOf(txtfiltro) >= 0 ||
            it["nobusqueda"].indexOf(txtfiltro) >= 0 ||
            it["descripcionesbusqueda"].indexOf(txtfiltro) >= 0 ||
            it["subfamilybusqueda"].indexOf(txtfiltro) >= 0
          );
        });
      }
      if (this.filtrotipologia !== "")
        lst = lst.filter(it => it.Typology === this.filtrotipologia);
      if (this.filtrosubfamilia !== "")
        lst = lst.filter(
          it => it.subfamily.indexOf(this.filtrosubfamilia) >= 0
        );

      //console.log(lst);
      if (this.filtrotipodocumento !== "") {
        //console.log(this.filtrotipodocumento);
        lst = lst.filter(
          it => parseInt(it.Type) === parseInt(this.filtrotipodocumento)
        );
      }
      if (this.filtroformato !== "")
        lst = lst.filter(it => it.ExtFile === this.filtroformato);

      //se busca
      /*'TypologyDocument',
          'TypeDocument',
          'ExtFile',
          'No',
          'Description',
          'Subfamily'*/
      //console.log("filtros", lst);
      this.onFiltered(lst);
      this.isBusy = false;
    }
  },
  watch: {
    filtrotipologia(newValue, oldValue) {
      if (newValue !== oldValue) this.filtros();
    },
    filtrosubfamilia(newValue, oldValue) {
      if (newValue !== oldValue) this.filtros();
    },
    filtroformato(newValue, oldValue) {
      if (newValue !== oldValue) this.filtros();
    },
    filtrotipodocumento(newValue, oldValue) {
      if (newValue !== oldValue) this.filtros();
    }
  }
};
</script>
<style>
#my-table td.descripcion {
  /* width: 40% !important; */
}
#my-table td.descripcion .subfamilia {
  font-size: 0.8em;
  color: darkgrey;
}
#my-table td.descarga {
  /* min-width: 14em !important; */
}
.embed-responsive-img {
  margin: auto !important;
}
.embed-responsive-img img {
  width: auto !important;
  height: auto !important;
}
</style>
