<template>
  <b-container class="contendordepagina">
    <div v-if="vuexCargandoUsuario" class="text-center text-danger my-2">
      <b-spinner class="align-middle mr-2"></b-spinner>
      <strong>{{ $t("lang.cargando") }}...</strong>
    </div>
    <div v-else>
      <div v-if="vuexAuthUsuario && vuexNombreUsuario.length">
        <Buscador></Buscador>
      </div>
      <div v-else>
        <b-jumbotron class="alert-danger">
          <span class="font-weight-bold">{{
            $t("lang.home.noestasautenticado")
          }}</span
          ><br />
          <!-- Para poder hacer uso de esta herramienta, debes acceder a través del <a href="//portal.manusa.com">Portal de empleado</a> -->
        </b-jumbotron>
      </div>
    </div>
  </b-container>
</template>

<script>
import { mapGetters } from "vuex";
import Buscador from "@/components/Buscador.vue";

export default {
  name: "Home",
  components: { Buscador },
  data() {
    return {
      seccionesdisponibles: [
        {
          texto: this.$t("lang.rutas.misfacturas"),
          nombre: "misfacturas",
          icono: "",
          enlace: "/mis-facturas"
        },
        {
          texto: this.$t("lang.rutas.vencimientos"),
          nombre: "vencimientos",
          icono: "",
          enlace: "/vencimientos"
        }
      ]
    };
  },
  mounted() {
    this.$store.commit("SET_SECCION", this.$t("lang.rutas.inicio"));
    //console.log("this.$store.getters", this.$store.getters)
  },
  computed: {
    ...mapGetters({
      vuexSeccion: "seccion",
      vuexAuthUsuario: "user/autenticado",
      vuexNombreUsuario: "user/nombre",
      vuexCargandoUsuario: "user/cargando"
    })
  }
};
</script>

<style scoped>
.btn-inverso,
a.btn-inverso:link,
a.btn-inverso:visited {
  color: var(--color-manusa-azul) !important;
  background-color: #fff !important;
}
.btn-inverso:hover,
a.btn-inverso:hover {
  color: #fff !important;
  background-color: var(--color-manusa-azul) !important;
  font-weight: normal !important;
}
.btn-circular {
  margin: 1em 0;
  border-radius: 7em;
  height: 8em;
  font-size: 1.5em;
}

.btn-ztactivo {
  color: rgb(206, 156, 20) !important;
  border-color: rgb(206, 156, 20) !important;
  font-weight: normal !important;
}
.zt {
  display: block;
  display: none;
}
.enlaceseccion {
  font-size: 3em;
  font-weight: 100;
  margin: 0 auto;
  text-decoration: none;
}
.enlaceseccion:hover {
  text-decoration: none;
  color: darkblue;
}
.centrar {
  display: flex;
  align-items: center;
}
</style>
