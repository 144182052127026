"use strict";
import axios from "axios";
//axios.defaults.withCredentials = true
axios.defaults.baseURL =
  process.env.NODE_ENV !== "production"
    ? process.env.VUE_APP_API_MANUSA_DEV
    : process.env.VUE_APP_API_MANUSA_PROD;
////console.log(axios.defaults)

export default {
  namespaced: true,
  state: {
    username: "",
    nombre: "",
    email: "",
    acronimo: "",
    token: "",
    autenticado: false,
    cargando: true
  },
  actions: {
    async logout({ commit }) {
      axios
        .post(
          "edocuments/cerrarsesion",
          {},
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${this.getters["user/token"]}`
            }
          }
        )
        .then(res => {
          sessionStorage.removeItem("token");
          commit("SET_USER", res.data);
          document.location.href = "/";
          ////console.log(this.getters)
        })
        .catch(function(error) {
          console.log(error);
        })
        .then(function() {
          // always executed
        });
    },
    async getUser({ commit }) {
      ////console.log("getUser", this.getters["user/token"]);
      if (this.getters["user/token"] != "") {
        axios
          .post(
            "/accion",
            {},
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${this.getters["user/token"]}`
              }
            }
          )
          .then(res => {
            if ("error" in res.data && res.data.error !== null) {
              if (res.data.error === "ERRORCONEXIONDB")
                document.location.href = "/error-conexion";
              commit("SET_USER", null);
            } else commit("SET_USER", res.data.usuario);
            ////console.log(this.getters)
          })
          .catch(function(error) {
            console.log(error);
            commit("SET_USER", null);
          })
          .then(function() {
            // always executed
          });
      } else {
        commit("SET_USER", null);
        return false;
      }
    },
    setToken({ commit }, token) {
      ////console.log("setToken", token)
      commit("SET_TOKEN", token);
      ////console.log("setToken - final", this.getters["user/token"])
    }
  },
  mutations: {
    SET_TOKEN(state, token) {
      ////console.log("SET_TOKEN", token)
      state.token = token;
      ////console.log("SET_TOKEN - final", state.token)
      state.autenticado = true;
    },
    SET_USER(state, user) {
      ////console.log(user)
      state.cargando = false;
      if (user) {
        var arr_acronimo = user.nombre.match(/\b(\w)/g);

        state.username = user.samaccountname;
        state.acronimo = arr_acronimo.join("").toUpperCase();
        state.nombre = user.nombre;
        state.email = user.email;
      } else {
        state.username = "";
        state.acronimo = "";
        state.nombre = "";
        state.email = "";
        state.token = "";
        state.autenticado = false;
      }
    }
  },
  getters: {
    username: state => {
      return state.username;
    },
    nombre: state => {
      return state.nombre;
    },
    email: state => {
      return state.email;
    },
    acronimo: state => {
      return state.acronimo;
    },
    token: state => {
      ////console.log("getters - token", state.token)
      return state.token;
    },
    autenticado: state => {
      return state.autenticado;
    },
    cargando: state => {
      return state.cargando;
    }
  }
};
